import { useTranslation } from 'react-i18next';
import { formatPhoneNumberIntl, parsePhoneNumber } from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface MobilePhoneProps {
 phone: string;
}

export const MobilePhone = ({ phone }: MobilePhoneProps) => {
 const { t } = useTranslation();
 const parsedPhoneNumber = phone ? parsePhoneNumber(phone) : null;

 if (!parsedPhoneNumber) {
  return (
   <Typography variant="body2" component="span">
    {t('EMPTY_PHONE_NUMBER')}
   </Typography>
  );
 }

 const phoneCountryCode = parsedPhoneNumber.country;
 const FlagIcon = phoneCountryCode ? flags[phoneCountryCode] : null;

 return (
  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
   {FlagIcon && (
    <Box sx={{ width: '21px', height: '18px', marginRight: '8px' }}>
     <FlagIcon title={`${phoneCountryCode} country flag`} />
    </Box>
   )}
   <Typography variant="subtitle2" component="span">
    {formatPhoneNumberIntl(phone)}
   </Typography>
  </Box>
 );
};
