import { Action, AnyAction } from 'redux';
import { Container } from 'typedi';
import { ActionsObservable, combineEpics } from 'redux-observable';
import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/concatMap';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/mergeMap';
import { AvailableLoadsRepository } from 'app/repositories/available-loads.repository';
import { NavCarrierEpic } from 'store/nav-carrier-epic.interface';
import * as a from './search-history.actions';
import { SEARCH_AVAILABLE_LOADS_SUCCESS } from 'shared/find-loads/redux/find-loads.actions';

interface EpicDependencies {
  searchHistoryRepo: AvailableLoadsRepository;
}

type SearchHistoryEpic<OutputAction extends Action = AnyAction> = NavCarrierEpic<OutputAction, EpicDependencies>;

export const fetchSearchHistoryLocationsEpic: SearchHistoryEpic = (action$, state$, { searchHistoryRepo }) =>
  action$.ofType(a.FETCH_SEARCH_HISTORY_LOCATIONS, SEARCH_AVAILABLE_LOADS_SUCCESS)
    .mergeMap(() =>
      searchHistoryRepo.getSearchHistoryLocations(state$.value?.auth?.user?.userId)
        .map((searchHistory) => a.fetchSearchHistoryLocationsSucceeded(searchHistory))
        .catch((res: AjaxError) => ActionsObservable.of(a.fetchSearchHistoryLocationsFailed(res)))
    );

export const searchHistoryEpic = (action$, state$) =>
  combineEpics(
    fetchSearchHistoryLocationsEpic,
  )(action$, state$, {
    searchHistoryRepo: Container.get(AvailableLoadsRepository)
  });
