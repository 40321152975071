import { Trans, useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import * as Styles from 'features/account-settings/profile-settings/styles/ChangePhoneNumberDialog.styles';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import Divider from '@mui/material/Divider';
import { PhoneNumberType, RegistrationPhoneNumberInput } from '@features/registration';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

interface BodyProps {
 currentNumber: string;
 phoneNumber: string;
 hasError: boolean;
 setPhoneNumber: (number: string) => void;
}

interface ActionsProps {
 isLoading: boolean;
 onSendSMS: () => void;
}

const Body = ({ currentNumber, phoneNumber, hasError, setPhoneNumber }: BodyProps) => {
 const { t } = useTranslation();

 return (
  <>
   <Typography variant="body1" sx={Styles.DialogBody}>
    {t('PHONE_NUMBER_NEEDS_TO_BE_ACCURATE')}
   </Typography>
   <Typography variant="body1" sx={{ ...Styles.DialogBody, marginTop: '16px' }}>
    <Trans i18nKey="YOUR_CURRENT_PHONE_NUMBER" components={[<strong>{formatPhoneNumberIntl(currentNumber)}</strong>]} />
   </Typography>
   <Divider variant="middle" sx={{ marginY: '24px' }} />
   <Box sx={{ width: { sm: '70%' }, margin: '0 auto 24px' }}>
    <InputLabel>{t('MOBILE_PHONE_NUMBER')}</InputLabel>
    <RegistrationPhoneNumberInput phoneNumber={phoneNumber} handlePhoneNumber={setPhoneNumber} error={hasError && t('INVALID_PHONE_NUMBER')} />
   </Box>
  </>
 );
};

const Actions = ({ isLoading, onSendSMS }: ActionsProps) => {
 const { t } = useTranslation();

 return (
  <Button
   sx={{ height: '56px' }}
   color="primary"
   variant="contained"
   onClick={onSendSMS}
   startIcon={isLoading && <CircularProgress sx={{ color: 'rgba(0, 0, 0, 0.28)' }} size={18} />}
   disabled={isLoading}
   fullWidth
  >
   {t('VERIFY_VIA_SMS')}
  </Button>
 );
};

export const ChangePhoneNumberDialogStepOne = {
 Body,
 Actions,
};
