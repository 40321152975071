import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'app/hooks/store/use-selector.hook';
import { useUserAndCarrierDataContext } from 'features/userAndCarrierData';
import { useTranslation } from 'react-i18next';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { useSnackbar } from '@chr/eds-react';
import { activateUserMfaSmsFactor, createUserMfaSmsFactor, getUserMfaSmsFactor, updateUserMfaSmsFactor, putUserProfile } from 'api/userManagement';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import { PhoneNumberType } from '@features/registration';
import {
 ChangePhoneNumberDialogStepOne as StepOne,
 ChangePhoneNumberDialogStepTwo as StepTwo,
} from 'features/account-settings/profile-settings/components';
import * as Styles from 'features/account-settings/profile-settings/styles/ChangePhoneNumberDialog.styles';

const OKTA_SMS_TIMEOUT = 30;

interface ChangePhoneNumberDialogProps {
 currentNumber: string;
 isOpen: boolean;
 onClose: () => void;
 onConfirm: (phone: string) => void;
}

export const ChangePhoneNumberDialog = ({ currentNumber, isOpen, onClose, onConfirm }: ChangePhoneNumberDialogProps) => {
 const { t } = useTranslation();
 const snackbar = useSnackbar();
 const { updateUser } = useUserAndCarrierDataContext();

 const { user, activeMembership } =
  useSelector(s => ({
   user: s.auth.user,
   activeMembership: s.userManagement.activeMembership,
  })) || {};

 const timerRef = useRef<NodeJS.Timeout>();

 const [step, setStep] = useState<'one' | 'two'>('one');
 const [phoneNumber, setPhoneNumber] = useState(currentNumber || '');
 const [factorId, setFactorId] = useState<string | null>(null);
 const [otp, setOtp] = useState('');
 const [isLoading, setIsLoading] = useState(false);
 const [isPhoneNumberInvalid, setIsPhoneNumberInvalid] = useState(false);
 const [isOtpInvalid, setIsOtpInvalid] = useState(false);
 const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
 const [resendDisabled, setResendDisabled] = useState(false);
 const [countdown, setCountdown] = useState(OKTA_SMS_TIMEOUT);

 const oktaUserId = JSON.parse(localStorage.getItem('okta-token-storage'))?.accessToken?.claims?.uid;
 const userId = activeMembership?.userId;

 const resetProcessValues = () => {
  setStep('one');
  setPhoneNumber(currentNumber);
  setFactorId(null);
  setOtp('');
  setIsPhoneNumberInvalid(false);
  setIsOtpInvalid(false);
 };

 const handleChangeOtp = newValue => {
  setOtp(newValue);
 };

 const handleClose = () => {
  setIsConfirmationOpen(true);
 };

 const handleConfirmClose = () => {
  setIsConfirmationOpen(false);
  resetProcessValues();
  onClose();
 };

 const handleCancelClose = () => {
  setIsConfirmationOpen(false);
 };

 const updateUserInStoreAndFinishProcess = async (updatedPhone, successSnackbarMessage) => {
  const { firstName, lastName, email, phone } = user || {};

  const {
   country,
   culture,
   preferredCurrencyCode,
   startPage,
   phoneType = PhoneNumberType.MOBILE,
   timeFormat,
   unitOfMeasure,
   timeZone,
  } = user?.properties || {};

  const requestBody = {
   firstName,
   lastName,
   email,
   phoneType,
   phone,
   startPage,
   country,
   culture,
   preferredCurrencyCode,
   unitOfMeasure,
   timeFormat,
   timeZone: timeZone?.id,
   hasVerifiedPhone: true,
  };

  await putUserProfile(userId, { ...requestBody, phone: updatedPhone });
  updateUser();

  resetProcessValues();

  snackbar.enqueueSnackbar({
   id: 'update-phone-number-success',
   message: successSnackbarMessage,
   severity: 'success',
  });

  onConfirm(phoneNumber);
 };

 const handleSendSMS = async () => {
  // Validate phone number format
  if (!isPossiblePhoneNumber(phoneNumber)) {
   setIsPhoneNumberInvalid(true);
   return;
  }

  // No need to send SMS again because the phone number is the same
  if (currentNumber === phoneNumber) {
   return;
  }

  setIsLoading(true);
  try {
   const userFactor = await getUserMfaSmsFactor(oktaUserId);

   // Send SMS for a non-existing or pending activation factor
   if (!userFactor?.smsFactor || userFactor?.smsFactor?.status === 'PENDING_ACTIVATION') {
    const createSmsFactor = await createUserMfaSmsFactor(oktaUserId, phoneNumber);
    setFactorId(createSmsFactor.smsFactor.id);
   }

   // Send SMS for updating existing factor
   if (userFactor?.smsFactor?.status === 'ACTIVE') {
    const updateMfaSmsFactor = await updateUserMfaSmsFactor(oktaUserId, userFactor.smsFactor.id, phoneNumber);
    setFactorId(updateMfaSmsFactor.smsFactor.id);
   }

   // Start a timer to disallow spamming SMS requests
   startResendTimer();

   // Move to next step with a format validated phone number and OTP already sent to the user
   setStep('two');
  } catch (error) {
   // TODO: Handle API generic error
   console.error(error);
  } finally {
   setIsLoading(false);
  }
 };

 const startResendTimer = () => {
  // Clear any existing timer
  if (timerRef.current) {
   clearInterval(timerRef.current);
  }

  setResendDisabled(true);
  setCountdown(OKTA_SMS_TIMEOUT);

  timerRef.current = setInterval(() => {
   setCountdown(prev => {
    if (prev <= 1) {
     if (timerRef.current) {
      clearInterval(timerRef.current);
     }
     setResendDisabled(false);
     return 0;
    }
    return prev - 1;
   });
  }, 1000);
 };

 const handleVerifySMS = async () => {
  if (otp.length !== 6) return;
  if (!factorId) return;

  setIsLoading(true);
  try {
   await activateUserMfaSmsFactor(oktaUserId, factorId, otp);

   updateUserInStoreAndFinishProcess(phoneNumber, t('PHONE_NUMBER_UPDATED'));
  } catch (error) {
   if (error.response.data.detail === 'Invalid Passcode/Answer') {
    setIsOtpInvalid(true);
    return;
   }
   // TODO: Handle API generic error
   console.error(error);
  } finally {
   setIsLoading(false);
  }
 };

 useEffect(() => {
  return () => {
   if (timerRef.current) {
    clearInterval(timerRef.current);
   }
  };
 }, []);

 return (
  <>
   <Dialog
    open={isOpen && !isConfirmationOpen}
    onClose={handleClose}
    aria-labelledby="change-phone-dialog-title"
    aria-describedby="change-phone-dialog-description"
   >
    <DialogTitle sx={Styles.DialogTitleContainer} id="change-phone-dialog-title">
     <Typography variant="subtitle1" sx={Styles.DialogTitle} component="span">
      {t('CHANGE_YOUR_PHONE_NUMBER')}
     </Typography>
     <IconButton aria-label="close" onClick={handleClose} sx={Styles.DialogCloseButton}>
      <CloseIcon />
     </IconButton>
    </DialogTitle>
    <DialogContent id="change-phone-dialog-description">
     {step === 'one' && (
      <StepOne.Body
       currentNumber={currentNumber}
       phoneNumber={phoneNumber}
       hasError={isPhoneNumberInvalid}
       setPhoneNumber={n => {
        setPhoneNumber(n);

        if (isPhoneNumberInvalid) {
         setIsPhoneNumberInvalid(false);
        }
       }}
      />
     )}
     {step === 'two' && (
      <StepTwo.Body
       phoneNumber={phoneNumber}
       otpValue={otp}
       hasError={isOtpInvalid}
       onOtpChange={otp => {
        handleChangeOtp(otp);

        if (isOtpInvalid) {
         setIsOtpInvalid(false);
        }
       }}
      />
     )}
    </DialogContent>
    <DialogActions sx={Styles.DialogActions}>
     {step === 'one' && <StepOne.Actions onSendSMS={handleSendSMS} isLoading={isLoading} />}
     {step === 'two' && (
      <StepTwo.Actions
       verifyDisabled={otp.length !== 6}
       resendDisabled={resendDisabled}
       countdown={countdown}
       onResendSMS={handleSendSMS}
       onVerifySMS={handleVerifySMS}
       isLoading={isLoading}
      />
     )}
    </DialogActions>
   </Dialog>
   <Dialog
    open={isConfirmationOpen}
    onClose={handleCancelClose}
    aria-labelledby="close-confirmation-dialog-title"
    aria-describedby="close-confirmation-dialog-description"
   >
    <DialogTitle sx={Styles.DialogTitleContainer} id="close-confirmation-dialog-title">
     <Typography variant="subtitle1" sx={Styles.DialogTitle} component="span">
      {t('ARE_YOU_SURE_YOU_WANT_LEAVE')}
     </Typography>
     <IconButton aria-label="close" onClick={handleCancelClose} sx={Styles.DialogCloseButton}>
      <CloseIcon />
     </IconButton>
    </DialogTitle>
    <DialogContent id="close-confirmation-dialog-description">
     <Typography variant="body1" sx={Styles.DialogBody}>
      {t('CONFIRM_DISCARD_PHONE_UPDATE')}
     </Typography>
    </DialogContent>
    <DialogActions>
     <Button onClick={handleCancelClose} variant="outlined">
      {t('CONTINUE')}
     </Button>
     <Button onClick={handleConfirmClose} variant="contained">
      {t('LEAVE')}
     </Button>
    </DialogActions>
   </Dialog>
  </>
 );
};
